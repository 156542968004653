<template>
  <ValidationObserver ref="form" tag="form" @submit.prevent="onSubmit">
    <app-dialog
      v-model="open"
      title="user.changePassword"
      primary-action-text="resetPassword.updateNow"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      :disabled="!password || !confirmation"
      @submit="onSubmit"
      @close="onClose"
    >
      <p class="mb-4">
        {{ $t('resetPassword.info') }} {{ $t('resetPassword.passwordRequirements') }}
      </p>
      <app-alert class="mb-4" :value="oldPasswordUsed">
        {{ $t('resetPassword.oldPasswordUsed') }}
      </app-alert>
      <confirm-password :password.sync="password" :confirmation.sync="confirmation" />
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.ok"
      title="resetPassword.updateComplete"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('resetPassword.confirmUpdate') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Authentication/store';
import { ERROR_CODE_OLD_PASSWORD } from '@/statics/errorCodes';
import showSnackbar from '@/helper/showSnackbar';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import ConfirmPassword from '@/shared/components/ConfirmPassword';

export default {
  name: 'ChangePasswordDialog',

  mixins: [ShowDialogMixin],

  components: { ConfirmPassword },

  data: () => ({
    password: '',
    confirmation: '',
    oldPasswordUsed: false
  }),

  methods: {
    ...mapActions(NAMESPACE, ['updatePassword']),

    onClose() {
      this.resetForm();
      this.$emit('input', false);
    },

    async onSubmit() {
      this.oldPasswordUsed = false;

      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;
      const { error } = await this.updatePassword(this.password);
      this.loading = false;
      if (error) {
        if (error.response?.data.error === ERROR_CODE_OLD_PASSWORD) {
          this.oldPasswordUsed = true;
          return;
        }
        showSnackbar({ text: 'serverError', color: 'error' });
        return;
      }

      this.submitted = true;
      this.resetForm();
    },

    resetForm() {
      this.password = '';
      this.confirmation = '';
      this.$refs.form?.reset();
    }
  }
};
</script>
