<template>
  <div>
    <v-row>
      <v-col cols="12">
        <app-text-field
          id="new-password"
          :value="password"
          autocomplete="new-password"
          label="password"
          type="password"
          vid="password"
          required
          rules="min:8|password_constraints"
          @input="$emit('update:password', $event)"
        />
        <password :value="password" :strength-meter-only="true" :secure-length="8" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-text-field
          id="new-password-confirmation"
          :value="confirmation"
          autocomplete="new-password"
          label="resetPassword.repeatPassword"
          name="password"
          required
          rules="confirmed:password"
          type="password"
          @input="$emit('update:confirmation', $event)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter';

export default {
  name: 'ConfirmPassword',

  components: { Password },

  props: {
    password: {
      type: String,
      default: ''
    },
    confirmation: {
      type: String,
      default: ''
    }
  }
};
</script>
